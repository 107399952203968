.parentRegister form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.parentRegister input {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  padding: 1rem;
  width: 60%;
}

.parentRegister input::placeholder {
  font-family: "More sugar";
  font-size: 1rem;
  color: var(--text-beige);
}

.parentRegister label {
  margin-top: 1rem;
  text-align: left;
  width: 65%;
  color: var(--text-blue);
}

.parentRegister h1 {
  color: var(--text-red);
  font-size: 2rem;
  font-weight: 400;
  text-align: center;
  width: 40%;
  margin: auto;
  margin-top: 2rem;
}

.parentRegister button {
  background-color: var(--blue);
  border: none;
  font-family: "More sugar";
  color: var(--text-beige);
  font-size: 1rem;
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 2rem;
}

.parentRegister button:hover {
  text-decoration: underline;
  cursor: pointer;
}
