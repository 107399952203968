.button-delete {
  background-color: var(--red);
  color: var(--text-white);
  border-radius: 15px;
  padding: 0.5rem;
  text-align: center;
  cursor: pointer;
}

.button-edit {
  background-color: var(--yellow);
  font-family: "More Sugar";
  border-radius: 15px;
  font-size: 1.2rem;
  cursor: pointer;
}

.button-password {
  background-color: var(--green);
  font-family: "More Sugar";
  border-radius: 15px;
  font-size: 1.2rem;
  cursor: pointer;
}

.administration input,
.administration select {
  font-family: "More sugar";
  border-radius: 15px;
  font-size: 1rem;
}

.filter {
  margin: auto;
  font-size: 2rem;
}
.table-user {
  background-color: var(--pink-pale);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: space-evenly;
  border-radius: 15px;
}

.table-user h2 {
  text-align: center;
}
