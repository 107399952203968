.activiteDetails {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: var(--pink-pale);
  border-radius: 15px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.activiteDetails img {
  width: 100%;
  border-radius: 15px;
}

.activiteDetails h2 {
  color: var(--text-purple);
  margin-bottom: 0;
  margin-top: 50px;
}

.activiteDetails .address {
  width: 50%;
  align-self: center;
}

.activiteDetails .link {
  background-color: var(--purple);
  color: var(--text-white);
  width: 50%;
  margin: 10px auto;
  border-radius: 15px;
  font-size: 1.5rem;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
}

.activiteDetails .content {
  background-color: var(--beige);
  margin: 10px;
  border-radius: 15px;
}
