.footer ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  padding: 0;
}

.footer {
  margin-top: 20px;
  text-align: center;
}

.footer p {
  margin: 0;
}

.menu-accessibilite {
  display: flex;
  flex-direction: column;
}

.button-accessibilite {
  background-color: var(--yellow);
  font-family: "More Sugar";
  border-radius: 15px;
  font-size: 1.2rem;
  cursor: pointer;
}
