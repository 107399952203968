.about {
  text-align: center;
}

.about ul {
  list-style: none;
}

.button_choices {
  background-color: var(--pink-pale);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  margin: auto;
  margin-top: 10px;
  border-radius: 15px;
}

.button_choices li {
  background-color: var(--red);
  margin: 10px;
  padding: 0.6rem;
  border-radius: 15px;
  max-width: 40%;
  color: var(--text-white);
  align-self: center;
  cursor: pointer;
}

.button_choices a {
  text-decoration: none;
  color: var(--text-white);
}

.active {
  text-decoration: underline;
}
