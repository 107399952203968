.message-header {
  background-color: var(--red);
  display: flex;
  justify-content: space-evenly;
  margin: 0;
  align-items: center;
  color: var(--text-white);
  border-radius: 10px;
}

.message-header figure {
  width: 15%;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 0;
}

.message-header img {
  background-color: var(--yellow);
  border-radius: 25px;
}

.message-header h3 {
  margin-left: 5px;
}

.message-header button {
  background-color: var(--red);
  color: var(--text-white);
  border: none;
  font-family: "More sugar";
  font-size: 2rem;
  transition: 0.5s;
  cursor: pointer;
}

.message-header button:hover {
  font-size: 3rem;
}

.detail-button {
  background-color: var(--purple);
  border-radius: 15px;
  padding: 1rem;
  color: var(--text-white);
  text-decoration: none;
}

.message-block {
  display: flex;
  flex-direction: column;
  background-color: var(--pink-pale);
  margin: 0;
  border-radius: 10px;
  height: 70vh;
  text-align: center;
  justify-content: space-between;
}

.message-block p {
  margin: 0;
  margin-top: 10px;
}

.message-submit {
  display: flex;
  align-self: center;
  justify-self: flex-end;
  background-color: var(--green);
  border-radius: 15px;
  width: 90%;
  margin-bottom: 20px;
}

.message-block figure {
  width: 15%;
  margin-left: 10px;
  margin-right: 5px;
  margin-top: 5px;
  margin-bottom: 0;
}

.message-block img {
  background-color: var(--yellow);
  border-radius: 25px;
}

.message-submit textarea {
  background-color: var(--green);
  border: none;
  width: 80%;
  font-size: 1rem;
  font-family: "More sugar";
  color: var(--text-white);
  height: 2.5rem;
  align-self: center;
}
.message-submit textarea::placeholder {
  color: var(--text-white);
  font-size: 1.5rem;
}
.message-submit button {
  background-color: var(--purple);
  border: none;
  font-size: 1.5rem;
  border-radius: 15px;
  color: var(--text-white);
  font-family: "More sugar";
  width: 20%;
  margin: 10px;
  cursor: pointer;
}

.message-conversation {
  display: flex;
  flex-direction: column;
  width: 95%;
  height: 90%;
  align-content: flex-start;
  overflow-y: auto;
  scroll-behavior: smooth;
}

.message-conversation::-webkit-scrollbar {
  display: none;
}

.userMessage {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  align-self: end;
  border-radius: 15px;
  width: 70%;
}

.userMessage p {
  padding: 0.5rem;
  border-radius: 15px;
  color: var(--text-white);
  background-color: var(--green);
}

.otherMessage {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  align-self: start;
  border-radius: 15px;
  padding: 0.5rem;
  width: 70%;
}

.otherMessage p {
  padding: 0.5rem;
  border-radius: 15px;
  color: var(--text-white);
  background-color: var(--purple);
}

.userMessage img {
  height: 3rem;
  width: 3rem;
}

.otherMessage img {
  height: 3rem;
  width: 3rem;
}

.message-submit button:disabled {
  background-color: grey;
}

.messages {
  margin-top: 20px;
}

@media only screen and (min-width: 1024px) {
  .messagerie-desktop {
    display: flex;
    flex-direction: row-reverse;
  }
  .mobile {
    width: 80%;
  }
  .message-header figure {
    width: 10%;
  }
  .message-block figure {
    width: 10%;
  }
  .message-submit {
    justify-content: space-evenly;
  }

  .message-block {
    background-color: var(--beige);
  }

  .message-header button {
    display: none;
  }
}
