.mapContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.map {
  margin: auto;
  width: 90%;
  height: 40vh;
}

.toggleMap {
  background-color: var(--red);
  font-family: "More sugar";
  color: var(--text-white);
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 50%;
  border-radius: 15px;
  border: none;
}

.popup-content {
  display: flex;
  font-size: 0.8rem;
  flex-direction: column;
  justify-content: space-between;
  font-family: "More sugar";
  align-items: center;
}

.popup-content img {
  margin: auto;
  width: 50%;
  background-color: var(--yellow);
  border-radius: 50px;
}

.popup-content h2 {
  margin: 0;
}

.details-button {
  font-size: 1rem;
  justify-self: center;
  background-color: var(--purple);
  color: var(--text-white);
  text-decoration: none;
  padding: 0.6rem;
  border-radius: 15px;
}

.popup-content a {
  color: var(--text-white);
}

.leaflet-popup-close-button a {
  font-family: "More sugar";
}

.leaflet-popup-content-wrapper {
  background-color: var(--beige);
}

.toggleMap {
  cursor: pointer;
}
