.proRegister form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.proRegister input {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  padding: 1rem;
  width: 60%;
}

.proRegister input::placeholder {
  font-family: "More sugar";
  font-size: 1rem;
  color: var(--beige);
}

.proRegister label {
  margin-top: 1rem;
  text-align: left;
  width: 65%;
  color: var(--text-blue);
}

.proRegister h2 {
  color: var(--text-red);
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 2rem;
}

.proRegister button {
  background-color: var(--blue);
  border: none;
  font-family: "More sugar";
  color: var(--text-beige);
  font-size: 1rem;
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 2rem;
}

.proRegister button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.proRegister select {
  background-color: var(--blue);
  border: none;
  border-radius: 5px;
  font-family: "More sugar";
  width: 65%;
  color: var(--text-white);
  padding: 1rem;
  font-size: 1rem;
}
