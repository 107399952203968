.header {
  display: flex;
  position: sticky;
  top: 0;
  background-color: var(--beige);
  z-index: 1003;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
}

.header .logo {
  padding-top: 10px;
  padding-left: 10px;
  transform: rotate(-15deg);
  overflow: hidden;
}

.header em {
  padding: 0;
  border: 0;
  text-align: center;
  font-family: "More sugar";
}

.header .red {
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 15px 15px;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 15px;
  transform: rotate(-15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header .green {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 12px 15px;
  padding-bottom: 20px;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  max-width: 1.2rem;
  max-height: 1.2rem;
  border-radius: 15px;
  transform: rotate(15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header .blue {
  background-color: var(--blue);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 15px 15px;
  padding-bottom: 15px;
  font-size: 1.5rem;
  text-align: center;
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  border-radius: 15px;
  transform: rotate(-15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header .yellow {
  background-color: var(--yellow);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 12px 15px;
  padding-bottom: 18px;
  font-size: 1.8rem;
  text-align: center;
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  border-radius: 15px;
  transform: rotate(15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header .logout {
  background-color: var(--purple);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 5px 14px;
  padding-bottom: 10px;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  border-radius: 15px;
  transform: rotate(-15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header .green:hover {
  transform: rotate(-25deg) scale(1.1);
}

.header .yellow:hover {
  transform: rotate(-25deg) scale(1.1);
}

.header .logout:hover {
  transform: rotate(25deg) scale(1.1);
}

.header .red:hover {
  transform: rotate(25deg) scale(1.1);
}

.header .blue:hover {
  transform: rotate(25deg) scale(1.1);
}
.header ul {
  display: flex;
  list-style: none;
  padding: 0;
}

.header li {
  margin-top: 0;
  margin-right: 10px;
  width: 50px;
  height: 50px;
}

.header p {
  padding: 0;
  margin: 0;
}

.return-button {
  background-color: var(--blue);
  border: none;
  font-family: "More sugar";
  color: var(--text-white);
  font-size: 2rem;
  width: 5rem;
  margin-left: 2rem;
  margin-top: 1rem;
  border-radius: 15px;
  cursor: pointer;
}

.admin {
  background-color: var(--green);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  color: var(--text-white);
  padding: 12px 15px;
  padding-bottom: 20px;
  font-size: 1rem;
  text-align: center;
  cursor: pointer;
  max-width: 1.2rem;
  max-height: 1.2rem;
  border-radius: 15px;
  transform: rotate(15deg);
  transition: 1s;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

@media only screen and (min-width: 1024px) {
  .first {
    width: 15rem;
    margin-top: 0;
  }

  .header .logo {
    max-width: 100%;
    transform: rotate(-10deg);
    padding: 0;
  }

  .header ul {
    transform: scale(1.2);
    align-items: center;
  }

  .header {
    justify-content: space-evenly;
  }
}
