.profil {
  background-color: var(--pink-pale);
  border-radius: 30px;
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  grid-template-rows: 0.9fr 0.5fr 0.5fr 0.5fr 0.5fr 0.5fr 3.6fr;
  width: 95%;
  margin: 20px auto;
  text-align: center;
}
.avatar {
  grid-area: 1 / 1 / 4 / 2;
  width: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.avatar img {
  width: 80%;
  background-color: var(--yellow);
  border-radius: 60px;
}
.lastname {
  font-size: 2rem;
  grid-area: 1 / 2 / 2 / 3;
  margin: 0;
  align-self: end;
  color: var(--text-purple);
}
.firstname {
  grid-area: 2 / 2 / 3 / 3;
  margin: 0;
  color: var(--text-purple);
}
.role {
  margin: 0;
  font-size: 1.2rem;
  grid-area: 3 / 2 / 4 / 3;
  color: var(--text-blue);
}

.address {
  grid-area: 4 / 1 / 6 / 2;
  color: var(--text-blue);
}

.button-message {
  grid-area: 4 / 2 / 5 / 3;
  font-family: "More sugar";
  background-color: var(--red);
  width: 80%;
  margin: auto;
  border: none;
  padding: 0.5rem 0.2rem;
  font-size: 1.2rem;
  border-radius: 15px;
  color: var(--text-white);
  text-decoration: none;
}
.button-dispo {
  grid-area: 5 / 2 / 6 / 3;
  font-family: "More sugar";
  background-color: var(--green);
  width: 80%;
  margin: auto;
  border: none;
  padding: 0.5rem 0.2rem;
  font-size: 1.2rem;
  border-radius: 15px;
  color: var(--text-white);
  text-decoration: none;
}
.button-choices {
  grid-area: 6 / 1 / 7 / 3;
  display: flex;
  list-style: none;
  justify-content: space-evenly;
  padding: 0;
}

.button-choices li {
  background-color: var(--purple);
  color: var(--text-white);
  border-radius: 15px;
  padding: 0.5rem;
  cursor: pointer;
}
.table-profil {
  grid-area: 7 / 1 / 8 / 3;
  background-color: #f5eee6;
  border-radius: 15px;
  margin: 20px;
  color: var(--text-blue);
  border-collapse: collapse;
  text-align: center;
}

table tr:not(:last-child) {
  border-bottom: 2px solid var(--pink-pale);
}

.table-profil td:nth-child(2) {
  text-align: center;
}

.selectedTab {
  text-decoration: underline;
}

@media only screen and (min-width: 1024px) {
  .profil {
    width: 50%;
  }

  .profil img {
    width: 60%;
  }
  .address {
    font-size: 1.5rem;
  }
  .lastname {
    font-size: 3rem;
  }
  .firstname {
    font-size: 2.5rem;
  }
}
