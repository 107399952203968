.messagerie h1 {
  text-align: center;
  font-size: 2rem;
  margin: 0;
  color: var(--text-purple);
}

.conversation {
  display: grid;
  background-color: var(--purple);
  border-radius: 15px;
  margin: 1rem;
  height: 10vh;
  grid-template-columns: 0.6fr 1.4fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    "figure name-block"
    "figure content";
  cursor: pointer;
}

.figure {
  display: flex;
  grid-area: figure;
  margin: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.figure img {
  background-color: var(--yellow);
  border-radius: 50px;
  height: 5rem;
}

.name-block {
  grid-area: name-block;
  color: var(--text-white);
  margin: 0;
  align-self: center;
}

.content {
  grid-area: content;
  margin: 0;
}

@media only screen and (min-width: 1024px) {
  .messagerie {
    margin-right: auto;
    overflow-y: auto;
    height: 80vh;
    width: 20rem;
    background-color: var(--pink-pale);
    border-radius: 40px;
    margin-top: 20px;
  }
  .messagerie::-webkit-scrollbar {
    display: none;
  }

  .mobile {
    margin-left: 20px;
  }
  .conversation {
    height: auto;
    background-color: var(--pink-pale);
  }

  .conversation h3 {
    color: var(--text-purple);
  }

  .conversation h1 {
    color: var(--text-purple);
  }

  .selected-conversation {
    background-color: var(--beige);
  }
}
