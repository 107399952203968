body {
  --pink-pale: #f3d7ca;
  --red: #f68484;
  --blue: #84a7f6;
  --green: #9cdbc8;
  --purple: #bd84f6;
  --yellow: #f6cc84;
  --beige: #fff8e3;
  --black: black;
  --text-white: white;
  --text-black: black;
  --text-red: #f68484;
  --text-blue: #84a7f6;
  --text-green: #9cdbc8;
  --text-yellow: #f6cc84;
  --text-purple: #bd84f6;
  --text-beige: #fff8e3;
  --text-pink-pale: #f3d7ca;
}

.App {
  text-align: center;
}

html,
body {
  height: 100%;
  margin: 0;
}

#root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.main {
  flex: 1;
}

.footer {
  flex-shrink: 0;
}
.leaflet-container {
  height: 50vh;
  width: 50%;
  border-radius: 2rem;
}

@media only screen and (min-width: 1024px) {
  main {
    margin: auto;
    width: 70rem;
  }
}
