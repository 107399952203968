.homecards {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-top: 1.5rem;
}

.card {
  background-color: var(--pink-pale);
  padding: 10px;
  border-radius: 30px;
  max-width: 40%;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 30px 30px 30px 100px 50px 100px;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-align: center;
  margin-bottom: 1rem;
}

.card figure {
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.card img {
  margin: 0;
  width: 80%;
  height: 100%;
  border-radius: 50px;
  background-color: var(--yellow);
}

.details {
  font-size: 0.8rem;
  background-color: var(--purple);
  border-radius: 15px;
  text-align: center;
  color: var(--text-white);
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  width: 90%;
}

.message {
  font-size: 2rem;
  background-color: var(--green);
  border-radius: 15px;
  text-align: center;
  color: var(--text-white);
  text-decoration: none;
  max-width: 50px;
  max-height: 50px;
  margin: auto;
}

.disponibilty {
  font-size: 0.8rem;
  background-color: var(--blue);
  border-radius: 15px;
  text-align: center;
  color: var(--text-white);
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: auto;
  padding: 10px;
}

.div1 {
  grid-area: 1 / 1 / 4 /2;
}
.div2 {
  color: var(--text-purple);
  font-size: 100%;
  margin: 0;
  grid-area: 1 / 2 / 2 / 3;
  white-space: nowrap;
}
.div3 {
  color: var(--text-purple);
  margin: 0;
  grid-area: 2 / 2 / 3 / 3;
  white-space: nowrap;
}
.div4 {
  font-size: 75%;
  margin: 0;
  grid-area: 3 / 2 / 4 / 3;
  white-space: nowrap;
}
.div5 {
  align-self: center;
  margin: 0;
  font-size: 0.8rem;
  color: var(--text-blue);
  grid-area: 4 / 1 / 5 / 2;
}
.div6 {
  grid-area: 4 / 2 / 5 / 3;
  padding: 0.5rem;
}
.div7 {
  color: var(--text-blue);
  grid-area: 5 / 1 / 6 / 3;
}
.div8 {
  grid-area: 6 / 1 / 7 / 2;
}
.div9 {
  grid-area: 6 / 2 / 7 / 3;
}

@media only screen and (min-width: 700px) {
  .card {
    width: 20%;
  }
}
