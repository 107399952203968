.postCard {
  background-color: var(--pink-pale);
  width: 45%;
  border-radius: 15px;
  margin-bottom: 20px;
}

.postCard figure {
  display: flex;
  width: 90%;
  padding: 0;
  margin: 10px 10px;
  height: 15rem;
  border-radius: 15px;
  overflow: hidden;
  align-items: center;
}

.postCard figure img {
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}

.infos {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1fr 2fr 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  margin-bottom: 1rem;
}

.infos .title {
  grid-area: 1 / 1 / 2 / 3;
  margin: 0;
  text-align: center;
  color: var(--text-purple);
}
.infos .type {
  grid-area: 2 / 1 / 3 / 3;
  text-align: center;
}
.infos .date {
  grid-area: 3 / 1 / 4 / 3;
  text-align: center;
}
.infos .address {
  grid-area: 3 / 2 / 4 / 3;
  font-size: 0.8rem;
  text-align: center;
}
.infos .button {
  grid-area: 4 / 1 / 5 / 3;
  margin: auto;
  background-color: var(--purple);
  color: var(--text-white);
  text-decoration: none;
  padding: 1rem;
  border-radius: 15px;
}

.infos em {
  margin-right: 5px;
}

@media only screen and (min-width: 1024px) {
  .postCard {
    background-color: var(--pink-pale);
    width: 20%;
    border-radius: 15px;
    margin-bottom: 20px;
  }
}
