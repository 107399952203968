.avatar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.avatar section {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  justify-content: center;
}

.avatar-grid img {
  width: 100%;
  height: auto;
  border-radius: 50%;
  cursor: pointer;
}

.avatar-grid img.select {
  border: 2px solid var(--blue);
}

.avatar button {
  margin: auto;
  max-width: 60%;
  background-color: var(--blue);
  border: none;
  font-family: "More sugar";
  color: var(--text-beige);
  font-size: 1rem;
  border-radius: 5px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  margin-top: 2rem;
  cursor: pointer;
}

.avatar h2 {
  color: var(--text-red);
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  width: 60%;
  margin: auto;
  margin-top: 2rem;
}
