.searchBar {
  list-style: none;
  background-color: var(--pink-pale);
  display: flex;
  justify-content: space-around;
  padding: 10px;
  position: relative;
}

.searchBar ul {
  list-style: none;
  display: flex;
  justify-content: space-evenly;
}

.searchBar li {
  display: flex;
  justify-content: center;
  margin-right: 10px;
  padding: 0;
}

.searchBar em {
  font-size: 2rem;
}

.bloc-top {
  background-color: var(--red);
  color: var(--text-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-radius: 15px;
  cursor: pointer;
  font-family: "More sugar";
  border: none;
  width: 30%;
  position: relative;
}

.bloc-top h3 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.bloc-top em {
  font-size: 20px;
  margin-right: 10px;
}

.bloc-links {
  width: 100%;
  background-color: var(--red);
  color: var(--text-white);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  position: absolute;
  transition: opacity 0.5s ease, transform 0.5s ease;
  transform: translateY(-20px);
}

.bloc-links.open {
  opacity: 1;
  transform: translateY(0);
}

.bloc-links:not(.open) {
  opacity: 0;
  pointer-events: none;
}

.dropdown-1 {
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
}

.dropdown-2 {
  position: absolute;
  top: 100%;
}

.dropdown-3 {
  position: absolute;
  top: 100%;
  text-align: center;
}

.dropdown-3 li {
  width: 100%;
}

.dropdown-3 input {
  font-family: "More sugar";
  background-color: var(--purple);
  border: none;
  color: var(--text-white);
  text-align: center;
}
.dropdown-3 input:focus {
  border-color: black;
}

.dropdown-3 button {
  background-color: var(--purple);
  color: var(--text-white);
  font-family: "More sugar";
  margin: auto;
  border: none;
  border-radius: 5px;
}

.dropdown-3 button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.bloc-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.bloc-links li {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  cursor: pointer;
}

.bloc-links li:hover {
  background-color: var(--pink-pale);
  color: var(--text-black);
}

.dropdown-3 li:hover {
  background-color: var(--red);
}

@media only screen and (min-width: 1024px) {
  .searchBar {
    border-radius: 15px;
  }
  .mapContainer {
    margin-top: 5vh;
  }

  .datePicker {
    justify-content: space-evenly;
    align-items: center;
  }

  .datePicker input {
    padding: 0.8rem;
  }

  .datePicker input::placeholder {
    color: var(--text-white);
  }

  .dropdown-3 button {
    padding: 1rem;
    margin: 0;
  }
}

input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}

input[type="checkbox"] + label:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 2px solid var(--text-white);
  border-radius: 3px;
  margin-right: 10px;
}

input[type="checkbox"]:checked + label:before {
  background-color: var(--blue);
}

input[type="checkbox"] + label {
  cursor: pointer;
}

input[type="checkbox"] + label:before,
input[type="checkbox"] + label:after {
  vertical-align: middle;
}

input[type="checkbox"] + label:hover:before {
  border-color: var(--blue);
}
