.register {
  text-align: center;
  height: 100vh;
}

.register a {
  text-decoration: none;
  margin: auto;
  border-radius: 31px;
  padding: 0.3rem;
  margin-bottom: 2rem;
  width: 50%;
  background-color: var(--purple);
  color: var(--text-beige);
  font-size: 2rem;
}

.register article {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
  width: 100%;
}

.register .logo {
  margin-top: 200px;
}

@media only screen and (max-width: 767px) {
  .register img {
    padding-top: 2rem;
    max-width: 100%;
  }
}
